/********************************************************************************
 * Module:          AuthDialog
 *
 * Description:     AODocs auth dialog giving context to the user and allowing
 *                  him to start the OAuth 2.0 flow when immediate auth failed
 *                  (as a user interaction is mandatory to prevent popup blocker)
 ********************************************************************************/

/**
 * Authentication modal dialog
 * @typedef {Object} AuthDialog
 * @property {String} name - name of the dialog, used as the css class name of the <dialog>
 * @property {Function} getContent - function returning DOM element to insert as part of the dialog content
 * @property {Function} [getNotes] - function returning DOM element to insert as part of the dialog notes section
 * @property {Function} [getFootnotes] - function returning DOM element to insert as footnotes
 * @property {String} [logo='aodocs-logo.svg'] - name of the svg from the images/ folder to use as the dialog image header
 * @property {Function} [onAuthButtonClick] - optional click event handler to replace the default one when clicking on the auth button
 */

import i18next from 'i18next';
import {authAction} from '../authMessage';

const requiredConfigKeys = ['name', 'getContent'];

export default class AuthDialog {

  /**
   * AuthDialog constructor.
   * @param {Object} config - dialog configuration
   */
  constructor(config = {}) {
    const missingConfigKeys = requiredConfigKeys.filter(key => !config[key]);
    if (missingConfigKeys.length > 0) {
      throw new Error('Missing required keys in the ' +
        `"${config.name}" dialog configuration: [${missingConfigKeys}]`);
    }
    this.logo = 'aodocs-logo.svg';
    this.beforeOpen = () => {};
    this.cancelButton = false;
    Object.keys(config).forEach(key => { this[key] = config[key]; });
  }

  /**
   * Create the dialog and append it to the DOM.
   * @param {AuthManager} authManager - AuthManager instance
   * @return {AuthDialog} the dialog instance
   */
  create(authManager) {
    if (!this.isCreated()) {
      this.authManager = authManager;
      this.dialog = _getDialog(this);
      document.body.appendChild(this.dialog);
    }
    return this;
  }

  /**
   * Open the dialog if not opened yet (or show it if it was hidden).
   * @return {AuthDialog} the dialog instance
   */
  open() {
    if (this.isCreated() && !this.isOpened()) {
      this.beforeOpen();
      this.dialog.showModal();
    } else if (this.dialog.classList.contains('ao-hidden')) {
      this.dialog.classList.remove('ao-hidden');
    }
    return this;
  }

  /**
   * Close the dialog if opened.
   * @return {AuthDialog} the dialog instance
   */
  close() {
    _close(this);
    return this;
  }

  /**
   * Hide the dialog (without closing it).
   * @return {AuthDialog} the dialog instance
   */
  hide() {
    this.dialog.classList.add('ao-hidden');
    return this;
  }

  /**
   * Get the dialog DOM element.
   * @return {HTMLElement|undefined} the dialog element, or undefined if not created yet or already disposed
   */
  getElement() {
    return this.dialog;
  }

  /**
   * Check if the dialog has been created and appended to the DOM.
   * @return {Boolean} true if the dialog has been created
   */
  isCreated() {
    return this.dialog !== undefined;
  }

  /**
   * Check if the dialog is currently opened.
   * @return {Boolean} true if the dialog is opened
   */
  isOpened() {
    return this.isCreated() && this.dialog.hasAttribute('open');
  }

  /**
   * Return the authDialog i18n label associated to the provided key.
   * @param {String|String[]} key - i18n key, or array of keys (the first one that resolves will be returned)
   * @param {Object} [options={}] - dynamic values for interpolation, formating etc.
   * @return {String} i18n label
   */
  static i18n(key, options = {}) {
    const keys = Array.isArray(key) ? key : [key];
    return i18next.t(keys.map(key => `authDialog.${key}`), options);
  }

}

/**
 * Close the current dialog.
 * @param {AuthDialog} dialog - dialog instance
 * @private
 */
function _close(dialog) {
  if (dialog.isOpened()) {
    dialog.getElement().classList.add('ao-dialog-closing');
    setTimeout(() => {
      // Close after the animation is finished
      dialog.getElement().close();
      dialog.getElement().classList.remove('ao-dialog-closing');
    }, 200);
  }
}

/**
 * Get the HTML5 dialog element.
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the dialog element container
 * @private
 */
function _getDialog(dialog) {
  const dialogElement = document.createElement('dialog');
  dialogElement.classList.add('ao-auth-dialog', `ao-${dialog.name}-dialog`);
  dialogElement.appendChild(_getTitle(dialog));
  dialogElement.appendChild(_getContent(dialog));
  dialogElement.appendChild(_getActions(dialog));
  dialogElement.addEventListener('close',
    () => dialog.authManager.sendMessage(authAction.dialogClosed));
  return dialogElement;
}

/**
 * Get the dialog title element.
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the dialog title element
 * @private
 */
function _getTitle(dialog) {
  const titleElement = document.createElement('div');
  titleElement.classList.add('ao-dialog-title');
  titleElement.appendChild(_getTitleImage(dialog));
  titleElement.appendChild(_getTitleText(dialog));
  return titleElement;
}

/**
 * Get the image element of the dialog title.
 * @param {AuthDialog} dialog - dialog instance
 * @returns {HTMLElement} the image element of the dialog title
 * @private
 */
function _getTitleImage(dialog) {
  const imageElement = document.createElement('img');
  imageElement.setAttribute('src', `images/${dialog.logo}`);
  return imageElement;
}

/**
 * Get the text element of the dialog title.
 * @returns {HTMLElement} the text element of the dialog title
 * @private
 */
function _getTitleText(dialog) {
  const titleText = AuthDialog.i18n([`${dialog.name}Title`, 'title']);
  const titleTextElement = document.createTextNode(titleText);
  const containerElement = document.createElement('span');
  containerElement.appendChild(titleTextElement);
  return containerElement;
}

/**
 * Get the dialog specific content.
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the dialog content container
 * @private
 */
function _getContent(dialog) {
  const notes = _getNotes(dialog);
  const footnotes = dialog.getFootnotes ? dialog.getFootnotes() : null;
  const contentElement = document.createElement('div');
  contentElement.classList.add('ao-dialog-content');
  contentElement.appendChild(dialog.getContent());
  if (notes) {
    contentElement.appendChild(notes);
  }
  if (footnotes) {
    contentElement.appendChild(footnotes);
  }
  return contentElement;
}

/**
 * Get the dialog notes section.
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the dialog notes container
 * @private
 */
function _getNotes(dialog) {

  const notes = dialog.getNotes ? dialog.getNotes() : null;

  if (!notes) {
    return null;
  }

  const notesContainerElement = document.createElement('div');
  notesContainerElement.classList.add('ao-note');
  notesContainerElement.innerHTML = `${AuthDialog.i18n('notesTitle')} `;

  if (Array.isArray(notes) && notes.length > 1) {
    const noteListElement = document.createElement('ul');
    notes.forEach(note => {
      const noteListItemElement = document.createElement('li');
      const noteListItemSpanElement = document.createElement('span');
      noteListItemSpanElement.innerHTML = note;
      noteListItemElement.appendChild(noteListItemSpanElement);
      noteListElement.appendChild(noteListItemElement);
    });
    notesContainerElement.appendChild(noteListElement);
  } else {
    const span = document.createElement('span');
    span.innerHTML = notes;
    notesContainerElement.appendChild(span);
  }

  return notesContainerElement;

}

/**
 * Get the dialog specific action buttons / links.
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the dialog actions container
 * @private
 */
function _getActions(dialog) {
  const actionsContainerElement = document.createElement('div');
  actionsContainerElement.classList.add('ao-actions');
  if (dialog.cancelButton) {
    actionsContainerElement.appendChild(_getCancelButton(dialog));
  }
  actionsContainerElement.appendChild(_getExcludeAccountButton(dialog));
  actionsContainerElement.appendChild(_getAuthButton(dialog));
  return actionsContainerElement;
}

/**
 * Get the button to exclude an account (disable the extension for this account).
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the exclude account button
 * @private
 */
function _getExcludeAccountButton(dialog) {
  const excludeButtonElement = document.createElement('button');
  excludeButtonElement.classList.add('ao-btn', 'ao-exclude-account');
  excludeButtonElement.innerHTML = AuthDialog.i18n('excludeAccountButton');
  excludeButtonElement.addEventListener('click', () => {
    dialog.authManager.sendMessage(authAction.disableForAccount);
    dialog.close();
  });
  return excludeButtonElement;
}

/**
 * Get the button to launch an auth flow.
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the button to open OAuth2 page
 * @private
 */
function _getAuthButton(dialog) {
  const openOAuthPopupButtonElement = document.createElement('button');
  openOAuthPopupButtonElement.classList.add('ao-btn', 'ao-action-btn', 'ao-interactive-auth');
  openOAuthPopupButtonElement.innerHTML = AuthDialog.i18n('authorizeButton');
  openOAuthPopupButtonElement.addEventListener('click', () => dialog.onAuthButtonClick
    ? dialog.onAuthButtonClick()
    : dialog.authManager.authorizeWithDefaultHandlers(false));
  return openOAuthPopupButtonElement;
}

/**
 * Get the dialog cancel button.
 * @param {AuthDialog} dialog - dialog instance
 * @return {HTMLElement} the dialog cancel button
 * @private
 */
function _getCancelButton(dialog) {
  const cancelButtonElement = document.createElement('button');
  cancelButtonElement.classList.add('ao-btn', 'ao-cancel');
  cancelButtonElement.innerHTML = AuthDialog.i18n('cancel');
  cancelButtonElement.addEventListener('click', () => _close(dialog));
  return cancelButtonElement;
}
