/*********************************************************
 * Module:          smartbarUpdateAuthDialog
 *
 * Description:     Auth dialog displayed if the
 *                  requested auth type is a scope update
 *********************************************************/

import Logger from '../../utils/logger';
import AuthDialog from '../authDialog';
import authConfig from '../../authConfig';
import {authAction, authError} from '../../authMessage';
import {revokeToken} from '../../authManager';
import smartbarAuthDialog from './authDialog';
import smartbarAuthDialogUtils from './authDialogUtils';

const logger = new Logger('AuthFrame', 'SmartbarUpdateAuthDialog');

const smartbarUpdateAuthDialog = new AuthDialog({

  name: 'sbUpdate',

  cancelButton: true,

  getContent: () => {
    const contentElement = document.createElement('div');
    contentElement.appendChild(_isGmailActivation()
      ? _getGmailActivationInfoSection()
      : _getRevokeAuthInfoSection());
    return contentElement;
  },

  getFootnotes: () => {
    return smartbarAuthDialogUtils.getGoogleUserDataPolicyDisclosure();
  },

  beforeOpen: () => {
    const dialog = smartbarUpdateAuthDialog.getElement();
    const excludeAccountBtn = dialog.querySelector('.ao-exclude-account');
    if (!_isGmailActivation()) {
      const authButton = dialog.querySelector('.ao-interactive-auth');
      authButton.innerHTML = AuthDialog.i18n('revokeAuthorizationButton');
    }
    excludeAccountBtn.classList.add('ao-hidden');
  },

  onAuthButtonClick: () => {
    if (_isGmailActivation()) {
      smartbarUpdateAuthDialog.authManager.authorizeWithDefaultHandlers(false);
    } else {
      // Get token to revoke from extension before requesting new authorization
      smartbarUpdateAuthDialog.authManager.sendMessage('getAccessToken', {}, data => {
        const error = chrome.runtime.lastError;
        if (error) {
          logger.error('onAuthButtonClick()', 'Failed to get access token to revoke', error);
          smartbarUpdateAuthDialog.authManager.sendError(authError.token_revocation_failed, error);
          return;
        }
        revokeToken(data.response)
          .then(() => {
            smartbarUpdateAuthDialog.hide();
            smartbarAuthDialog.create(smartbarUpdateAuthDialog.authManager).open();
            smartbarUpdateAuthDialog.authManager.sendMessage(authAction.tokenRevoked);
          })
          .catch(e => smartbarUpdateAuthDialog.authManager.sendError(authError.token_revocation_failed, e));
      });
    }
  }

});

/**
 * Get the section element explaining why existing authorization will be revoked.
 * @return {HTMLElement} "context" section element
 * @private
 */
function _getRevokeAuthInfoSection() {
  const contextInfoElement = document.createElement('div');
  contextInfoElement.classList.add('ao-explain');
  contextInfoElement.innerHTML = AuthDialog.i18n('revokeExistingAuth', {
    user: smartbarUpdateAuthDialog.authManager.loginHint,
    scopeList: _getScopeList(smartbarUpdateAuthDialog.authManager.scope.includes(authConfig.scopes.gmail))
  });
  return contextInfoElement;
}

/**
 * Get the section element explaining why Gmail authorization is needed.
 * @return {HTMLElement} "context" section element
 * @private
 */
function _getGmailActivationInfoSection() {
  const sectionElement = document.createElement('div');
  const contextInfoElement = document.createElement('div');
  const importEmailImg = document.createElement('img');
  contextInfoElement.innerHTML = AuthDialog.i18n('gmailActivation');
  contextInfoElement.classList.add('ao-gmail-activation');
  importEmailImg.setAttribute('src', 'images/importEmail.png');
  sectionElement.appendChild(contextInfoElement);
  sectionElement.appendChild(importEmailImg);
  return sectionElement;
}

/**
 * Get an HTML list of the authorized OAuth2 scopes.
 * @param {Boolean} isGmailGranted - true if the Gmail scope is granted
 * @return {String} readable HTML list of scopes
 * @private
 */
function _getScopeList(isGmailGranted) {
  let scopes = '';
  ['mail', 'drive'].forEach(scope => {
    if (scope !== 'mail' || isGmailGranted) {
      const logo = {logo: `<img src="images/google-${scope}-logo.png"/>`};
      scopes += `<div><span></span><span>${AuthDialog.i18n(scope + 'Scope', logo)}</span></div>`;
    }
  });
  return `<div class="ao-granted-scopes">${scopes}</div>`;
}

/**
 * Check if the requested update type corresponds to the activation of a Gmail feature.
 * @return {Boolean} the result of the check
 * @private
 */
function _isGmailActivation() {
  return smartbarUpdateAuthDialog.authManager.updateType === 'gmailActivation';
}

export default smartbarUpdateAuthDialog;
