/**************************************************************
 * Module:          AuthMessage
 *
 * Description:     Regroup messages and error codes posted by
 *                  the auth frame to the requestor extension
 **************************************************************/

export const authError = {
  immediate_failed: 'immediate_failed',
  immediate_failed_user_logged_out: 'immediate_failed_user_logged_out',
  popup_closed_by_user: 'popup_closed_by_user',
  popup_blocked_by_browser: 'popup_blocked_by_browser',
  access_denied: 'access_denied',
  auth_failed: 'auth_failed',
  token_validation_failed: 'token_validation_failed',
  auth_result_serialize_fail: 'auth_result_serialize_fail',
  account_mismatch: 'account_mismatch',
  token_revocation_failed: 'token_revocation_failed'
};

export const authAction = {
  setAuthInfo: 'setAuthInfo',
  dialogClosed: 'closeAuthDialog',
  disableForAccount: 'disableForAccount',
  tokenRevoked: 'tokenRevoked'
};
