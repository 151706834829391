/*****************************************************************
 * Module:          Init
 *
 * Description:     Auth frame entry point in charge of the
 *                  AuthManager instantiation once gapi is loaded
 *****************************************************************/

import AuthManager from './authManager';

// Global function called once gapi client is loaded
window.onClientLoaded = () => new AuthManager();
