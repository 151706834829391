/*****************************************
 * Module:          ufoAuthDialog
 *
 * Description:     Auth dialog for the
 *                  AODocs UFO extension
 ****************************************/

import AuthDialog from '../authDialog';

const ufoAuthDialog = new AuthDialog({

  name: 'ufo',

  logo: 'ufo-logo.svg',

  getContent: () => {
    const contentElement = document.createElement('div');
    contentElement.appendChild(_getContextInfoSection());
    contentElement.appendChild(_getScopeSection());
    return contentElement;
  },

  getNotes: () => AuthDialog.i18n('excludeAccountNote')

});

/**
 * Get the "context" section element, containing authentication context info.
 * @return {HTMLElement} "context" section element
 * @private
 */
function _getContextInfoSection() {
  const contextInfoElement = document.createElement('div');
  contextInfoElement.classList.add('ao-explain');
  contextInfoElement.innerHTML = AuthDialog.i18n('contextInfo', {user: ufoAuthDialog.authManager.loginHint});
  return contextInfoElement;
}

/**
 * Get a list of items representing application scopes.
 * @return {HTMLUListElement} "scopes" section element
 * @private
 */
function _getScopeSection() {
  const scopesList = document.createElement('ul');
  const scopeElement = document.createElement('li');
  const scopeText = document.createElement('span');
  scopesList.classList.add('ao-scopes');
  scopeText.innerHTML = AuthDialog.i18n('driveScope', {logo: '<img src="images/google-drive-logo.png"/>'});
  scopeElement.appendChild(scopeText);
  scopesList.appendChild(scopeElement);
  return scopesList;
}

export default ufoAuthDialog;
