/************************************************
 * Module:          I18nManager
 *
 * Description:     Internationalization manager
 *                  based on the i18next library
 ************************************************/

import Logger from './logger';
import i18next from 'i18next';
import i18nextXhrBackend from 'i18next-xhr-backend';

const logger = new Logger('AuthFrame', 'I18nManager');

export default class I18nManager {

  /**
   * Initialize the i18next library used for internationalization.
   * @param {String} lng - the locale code
   * @param {String} ns - namespace to load
   * @param {Function} [callback=()=>{}] - callback function executed once translations are loaded
   * @param {String} [fallbackLng=en] - language to use if translations are not available for the requested locale
   * @param {Boolean} [debug=false] - enable debug logs to the console. Helps finding issues with loading not working
   */
  static init(lng, ns, callback = () => {}, fallbackLng = 'en', debug = false) {
    i18next
      .use(i18nextXhrBackend)
      .init({
        lng,
        fallbackLng,
        debug,
        ns,
        defaultNS: ns,
        backend: {
          loadPath: 'locales/{{lng}}/{{ns}}.json'
        }
      }, () => {
        logger.debug('init()', 'I18nManager successfully inizialized');
        callback();
      });
  }

}
