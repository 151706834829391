/********************************************************
 * Module:          logger
 *
 * Description:     Provide a Logger class to print logs
 *                  to the console in a standardize way
 ********************************************************/

export default class Logger {

  /**
   * Logger constructor.
   * @param {String} appName - application name
   * @param {String} moduleName - module name
   */
  constructor(appName, moduleName) {
    this.appName = appName;
    this.moduleName = moduleName;
  }

  /**
   * Print a debug log to the console if the 'authFrame.debug'
   * key is set to true in the browser localStorage.
   * @param {String} functionName - calling function name
   * @param {...*} data - data/message to log
   * @see #_log
   */
  debug(functionName, ...data) {
    if (localStorage['authFrame.debug'] === 'true') {
      _log(this, console.debug, functionName, data);
    }
  }

  /**
   * Print a info log to the console.
   * @param functionName - calling function name
   * @param {...*} data - data/message to log
   * @see #_log
   */
  info(functionName, ...data) {
    _log(this, console.info, functionName, data);
  }

  /**
   * Print a warn log to the console.
   * @param functionName - calling function name
   * @param {...*} data - data/message to log
   * @see #_log
   */
  warn(functionName, ...data) {
    _log(this, console.warn, functionName, data);
  }

  /**
   * Print a error log to the console.
   * @param functionName - calling function name
   * @param {...*} data - data/message to log
   * @see #_log
   */
  error(functionName, ...data) {
    _log(this, console.error, functionName, data);
  }

}

/**
 * Print the given data to the console in the following format:
 * [appName - UTCDate] moduleName - functionName - data
 * @param {Logger} logger - Logger instance
 * @param {Object} consoleType - console object
 * @param {String} functionName - calling function name
 * @param {...*} data - data/message to log
 * @private
 */
function _log(logger, consoleType, functionName, data) {
  const consoleData = data.slice();
  consoleData.unshift(
    `%c[${logger.appName} - ${new Date().toUTCString()}]`,
    'color: grey;', logger.moduleName, '-', functionName, '-');
  Function.apply.call(consoleType, console, consoleData);
}
