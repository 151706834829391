/****************************************************
 * Module:          AuthConfig
 *
 * Description:     Auth frame related configuration
 ****************************************************/

const authConfig = {

  // Mandatory url query parameters
  // NOTICE: NEVER USE THE QUERY PARAMS LISTED BELOW as it may interfere
  // with gapi when loaded from within an iframe (window.self !== window.top):
  // [apppackagename, callback, clientid, cookiepolicy,
  // includegrantedscopes, openidrealm, requestvisibleactions, scope]
  mandatoryQueryParams: {
    extensionId: 'e',
    clientId: 'c',
    scope: 's',
    loginHint: 'lh',
    authType: 't',
    tabId: 'tid'
  },

  namespace: {
    ufo: 'ufo',
    smartbar: 'sb',
    awesomedrive: 'awd'
  },

  scopes: {
    email: 'email',
    profile: 'profile',
    drive: 'https://www.googleapis.com/auth/drive',
    gmail: 'https://www.googleapis.com/auth/gmail.readonly'
  },

  authType: {
    initial: 'initial',
    renewal: 'renewal',
    update: 'update'
  }

};

// All known query params
authConfig.queryParams = Object.assign({}, {
  locale: 'l',
  updateType: 'ut'
}, authConfig.mandatoryQueryParams);

// Use CommonJS module syntax as this config is also loaded at build time
// and our actual node version in use does not support ES6 module (v. 6.x)
module.exports = authConfig;
