/**************************************************************
 * Module:          smartbarAuthDialogUtils
 *
 * Description:     Utility module regrouping functions shared
 *                  by the different smartbar related dialogs
 **************************************************************/

import authConfig from '../../authConfig';
import AuthDialog from '../authDialog';

const envConfig = require(`../../config/env/${ENV}`);

export default {

  /**
   * Gets the OAuth2 parameters to use to launch the auth
   * flow, based on the scopes selected in the dialog.
   * @return {{clientId: String, scopes: String}} OAuth2 config
   */
  getAuthConfig: () => {
    const isGmailChecked = document.querySelector('#mailScope').checked;
    return {
      clientId: isGmailChecked ? envConfig.clientIds.smartbar : envConfig.clientIds.aodocs,
      scopes: _getOAuth2Scopes(isGmailChecked)
    };
  },

  /**
   * Gets the HTML element containing the disclosure about the
   * extension's compliance/adherence to the Google API Services User Data Policy.
   * @return {HTMLDivElement} Google API Services User Data Policy disclosure
   */
  getGoogleUserDataPolicyDisclosure: () => {
    const disclosure = document.createElement('div');
    disclosure.classList.add('google-user-data-policy-disclosure');
    disclosure.innerHTML = AuthDialog.i18n('googleUserDataPolicyDisclosure');
    return disclosure;
  }

};

/**
 * Gets OAuth2 scopes to request.
 * @return {String} space-separated string of OAuth2 scopes
 * @private
 */
function _getOAuth2Scopes(isGmailChecked) {
  const scopes = [
    authConfig.scopes.email,
    authConfig.scopes.profile,
    authConfig.scopes.drive
  ];
  if (isGmailChecked) {
    scopes.push(authConfig.scopes.gmail);
  }
  return scopes.join(' ');
}
