/*********************************************************************
 * Module:          AuthManager
 *
 * Description:     OAuth 2.0 authentication & authorization manager.
 *                  In charge of Google auth API loading and
 *                  authentication & authorization flow management
 *********************************************************************/

// TODO: implement a retry strategy in case of server error

import Logger from './utils/logger';
import I18nManager from './utils/i18nManager';
import {authAction, authError} from './authMessage';
import authConfig from './authConfig';
import ufoAuthDialog from './dialog/ufo/authDialog';
import smartbarAuthDialog from './dialog/smartbar/authDialog';
import awdAuthDialog from './dialog/awd/authDialog';
import smartbarNoAodocsAuthDialog from './dialog/smartbar/noAODocsAuthDialog';
import smartbarUpdateAuthDialog from './dialog/smartbar/updateAuthDialog';

const envConfig = require(`./config/env/${ENV}`);

const logger = new Logger('AuthFrame', 'AuthManager');

export default class AuthManager {

  /**
   * AuthManager constructor.
   */
  constructor() {

    const queryParams = _getQueryParameters();
    const queryParamKeys = authConfig.queryParams;

    if (_validateQueryParameters(queryParams)) {

      this.extensionId = queryParams[queryParamKeys.extensionId];
      this.tabId = queryParams[queryParamKeys.tabId];
      this.clientId = queryParams[queryParamKeys.clientId];
      this.scope = queryParams[queryParamKeys.scope];
      this.loginHint = queryParams[queryParamKeys.loginHint].toLowerCase(); // SB-1094
      this.authType = queryParams[queryParamKeys.authType];
      this.updateType = queryParams[queryParamKeys.updateType];
      this.locale = queryParams[queryParamKeys.locale] || navigator.language;
      this.checkSignedIn = queryParams[queryParamKeys.checkSignedIn] === 'true';
      this.namespace = _getNamespace(this.extensionId);
      this.queryParams = queryParams; // Allow to pass and use arbitrary params

      _init(this);

    }
  }

  /**
   * Initiates the OAuth 2.0 authorization process and use default success / error handlers.
   * @param {Boolean} [immediate=true] - true to try to seamlessly authorize, false to start an interactive auth
   * @see AuthManager#authorize
   */
  authorizeWithDefaultHandlers(immediate = true) {
    this.authorize(immediate, this.clientId, this.scope, validateToken, handleAuthError);
  }

  /**
   * Initiates the OAuth 2.0 authorization process to let the user authenticates and autorizes the application.
   * Depending on the context, the auth flow can either be transparent for the user or require an explicit validation.
   * @param {Boolean} immediate - true to try to seamlessly authorize, false to start an interactive auth
   * @param {String} clientId - the application's client id to use
   * @param {String} scope - the scopes to authorize
   * @param {Function} onSuccess - success callback function
   * @param {Function} onError - error callback function
   */
  authorize(immediate, clientId, scope, onSuccess, onError) {
    logger.info('authorize()',
      `Launching ${immediate ? 'immediate' : 'interactive'} auth for user ${this.loginHint}`);
    window.gapi.auth.authorize({
      client_id: clientId,
      scope,
      login_hint: this.loginHint,
      immediate,
      include_granted_scopes: false,
      authuser: ''
    }, authResult => authResult.error ? onError(this, authResult) : onSuccess(this, authResult));
  }

  /**
   * Check that the issued access token is valid.
   * @param {AuthManager} authManager - AuthManager instance
   * @param {GoogleApiOAuth2TokenObject} authResult - the OAuth 2.0 server response
   */
  static validateToken(authManager, authResult) {
    logger.info('validateToken()', 'Validating access token...');
    window.gapi.client
      .request({path: `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${authResult.access_token}`})
      .then(
        response => _sendAuthResponse(authManager, authResult, response.result),
        response => _handleTokenValidationError(authManager, response)
      );
  }

  /**
   * Revoke the provided OAuth2 access token if defined.
   * @param {String} accessToken - OAuth2 access token to revoke
   * @return {Promise} a token revikation promise
   */
  static revokeToken(accessToken) {
    logger.info('revokeToken()', 'Preparing to revoke application access token');
    return new Promise((resolve, reject) => {
      if (!accessToken) {
        logger.warn('revokeToken()', 'No application access token to revoke');
        reject();
      } else {
        fetch(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`, {mode: 'no-cors'})
          .then(() => {
            // Note: we can't know if the revocation succeeded as the response is opaque.
            // response.ok is always false, as this endpoint does no support cross origin requests.
            logger.info('revokeToken()', 'Application access token should be revoked');
            resolve();
          })
          .catch(e => {
            logger.error('revokeToken()', 'Failed to revoke application access token', e);
            reject(e);
          });
      }
    });
  }

  /**
   * Event handler executed when gapi.auth.authorize failed.
   * @param {AuthManager} authManager - AuthManager instance
   * @param {GoogleApiOAuth2TokenObject} authResult - the result of the auth in case of error
   */
  static handleAuthError(authManager, authResult) {

    const serializedAuthResult = serializeAuthResult(authManager, authResult) || {};
    const error = (serializedAuthResult.error || '').toLowerCase();

    switch (error) {

      case authError.immediate_failed:
        logger.warn('handleAuthError()',
          'Immediate auth failed, opening auth dialog to allow interactive mode', authResult);
        _getDialog(authManager).create(authManager).open();
        authManager.sendError(authError.immediate_failed, serializedAuthResult);
        break;

      case authError.immediate_failed_user_logged_out:
        logger.warn('handleAuthError()',
          `Immediate auth failed, user ${authManager.loginHint} is logged out`, authResult);
        serializedAuthResult.loggedOutUser = authManager.loginHint;
        authManager.sendError(authError.immediate_failed_user_logged_out, serializedAuthResult);
        break;

      case authError.popup_closed_by_user:
        logger.warn('handleAuthError()', 'Auth failed, consent screen popup closed by user', authResult);
        authManager.sendError(authError.popup_closed_by_user, serializedAuthResult);
        break;

      case authError.popup_blocked_by_browser:
        logger.error('handleAuthError()', 'Auth failed, consent screen popup blocked by browser', authResult);
        authManager.sendError(authError.popup_blocked_by_browser, serializedAuthResult);
        break;

      case authError.access_denied:
        logger.warn('handleAuthError()', 'Auth failed, user refused to grant permissions', authResult);
        authManager.sendError(authError.access_denied, serializedAuthResult);
        break;

      default:
        logger.error('handleAuthError()', 'Auth failed unexpectedly', authResult);
        authManager.sendError(authError.auth_failed, serializedAuthResult);

    }

  }

  /**
   * Remove not serializable properties from the authResult object, as
   * only JSON-ifiable object can be passed via chrome.runtime.sendMessage.
   * @param {AuthManager} authManager - AuthManager instance
   * @param {GoogleApiOAuth2TokenObject|{}} [authResult={}] - auth result data
   * @return {Object|String|null} serializable auth data
   */
  static serializeAuthResult(authManager, authResult = {}) {
    // Explicitly remove 'g-oauth-window', added in case of immediate=false, because JSON.stringify()
    // is not able to remove it in all cases: it generates a 'DOMException: Blocked a frame with origin
    // [...] from accessing a cross-origin frame' in case the user has clicked on cancel in the consent screen
    delete authResult['g-oauth-window'];
    try {
      return typeof authResult === 'object' ? JSON.parse(JSON.stringify(authResult)) : authResult;
    } catch (e) {
      logger.error('serializeAuthResult()', 'Failed to serialize auth result data:', e);
      authManager.sendError(authError.auth_result_serialize_fail, e);
      return null;
    }
  }

  /**
   * Send the given error to the requestor extension.
   * @param {String} reason - invariant error code
   * @param {String|Object} [details=''] - detailed error message
   */
  sendError(reason, details = '') {
    this.sendMessage('handleError', {error: {reason, details}});
  }

  /**
   * Send the given message to the requestor extension.
   * @param {String} action - string to identify the action to execute on the extension side
   * @param {Object} [data={}] - data to post
   * @param {Function} [responseCallback] - callback to execute when a response is expected
   */
  sendMessage(action, data = {}, responseCallback) {
    data.email = this.loginHint;
    data.tabId = this.tabId;
    data.authType = this.authType;
    data.updateType = this.updateType;
    data.responseExpected = !!responseCallback;
    chrome.runtime.sendMessage(this.extensionId, {manager: 'auth', action, data}, responseCallback || (() => {}));
  }

}

/**
 * Extract query parameters from the URL.
 * @return {Object} one key/value pair per query parameter
 * @private
 */
function _getQueryParameters() {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const result = {};
    for (const key of queryParams.keys()) {
      result[key] = queryParams.get(key);
    }
    return result;
  } catch (e) {
    return _getQueryParametersForOutdatedChrome();
  }

}

/**
 * /!\ TEMPORARY fallback for query parameters extraction for Chrome < 61 /!\
 * Once we will remove it, the Smartbar will no longer work (systematic
 * error in the auth process), no matter which version of the Smartbar is used
 * for users with a Chrome version 60 and below.
 * @see https://bugs.chromium.org/p/chromium/issues/detail?id=680531
 * @see https://altirnao.atlassian.net/browse/SB-1075
 * @private
 */
function _getQueryParametersForOutdatedChrome() {
  const queryParams = getUrlParams();
  const result = {};
  Object.keys(queryParams).forEach(key => {
    result[key] = queryParams[key];
    if (key === authConfig.queryParams.scope) {
      result[key] = result[key].replace(/\+/g, ' ');
    }
  });
  return result;
}
function getUrlParams() {
  const search = window.location.search;
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    return Object.assign(params, {[key]: decodeURIComponent(val)});
  }, {});
}

/**
 * Check if the URL query parameters are valid.
 * @param {Object} queryParams - key/value pairs representing URL parameters
 * @return {Boolean} the result of the check
 * @private
 */
function _validateQueryParameters(queryParams) {
  const missingQueryParams = Object.values(authConfig.mandatoryQueryParams).filter(param => !queryParams[param]);
  if (missingQueryParams.length > 0) {
    logger.error('_validateQueryParameters()', 'Missing required parameters:', missingQueryParams);
    return false;
  }
  const extensionId = queryParams[authConfig.queryParams.extensionId];
  if (!Object.values(envConfig.allowedExtensionIds).includes(extensionId)) {
    logger.error('_validateQueryParameters()',
      `Trying to obtain an access token from not allowed extension: '${extensionId}'`);
    return false;
  }
  return true;
}

/**
 * Initialize the auth flow.
 * @param {AuthManager} authManager - AuthManager instance
 * @private
 */
function _init(authManager) {
  const isAuthUpdate = authManager.authType === authConfig.authType.update;
  // For auth update, open the auth update dialog immediately
  // For initial auth or token renewal, start auth flow
  const i18nInitCallback = isAuthUpdate
    ? () => _getDialog(authManager, true).create(authManager).open()
    : () => authManager.authorizeWithDefaultHandlers();
  I18nManager.init(authManager.locale, authManager.namespace, i18nInitCallback);
}

/**
 * Get the namespace corresponding to the provided extension id.
 * @param {String} extensionId - id of the extension
 * @return {String|null} the namespace or null if none
 * @private
 */
function _getNamespace(extensionId) {
  for (const [namespace, id] of Object.entries(envConfig.allowedExtensionIds)) {
    if (id === extensionId) {
      return namespace;
    }
  }
  return null;
}

/**
 * Get the dialog instance associated to the provided namespace.
 * @param {AuthManager} authManager - AuthManager instance
 * @param {Boolean} [update=false] - true if the current auth in an update
 * @return {AuthDialog} the auth dialog instance
 * @private
 */
function _getDialog(authManager, update = false) {
  switch (authManager.namespace) {
    case authConfig.namespace.smartbar:
      return update || authManager.updateType === 'gmailActivation'
        ? smartbarUpdateAuthDialog
        : smartbarNoAodocsAuthDialog.isOpened()
          ? smartbarNoAodocsAuthDialog
          : smartbarAuthDialog;
    case authConfig.namespace.ufo:
      return ufoAuthDialog;
    case authConfig.namespace.awesomedrive:
      return awdAuthDialog;
    default:
      throw new Error(`Unknown namespace '${authManager.namespace}', you must implement a dialog for it`);
  }
}

/**
 * Send the auth result info to the requestor extension.
 * @param {AuthManager} authManager - AuthManager instance
 * @param {GoogleApiOAuth2TokenObject} authResult - the OAuth 2.0 server response
 * @param {Object} tokenInfo - access token related information
 * @private
 */
function _sendAuthResponse(authManager, authResult, tokenInfo) {
  const serializedAuthResult = serializeAuthResult(authManager, authResult);
  if (serializedAuthResult) {
    // Put in lower case to avoid false positive error in email comparison
    // and to normalize the case of the email returned to the caller (SB-1094)
    tokenInfo.email = tokenInfo.email.toLowerCase();
    if (tokenInfo.email === authManager.loginHint) {
      _getDialog(authManager).close();
      logger.info('_sendAuthResponse()', 'Token has been issue for the requested account', authManager.loginHint);
      authManager.sendMessage(authAction.setAuthInfo, {authResult: serializedAuthResult, tokenInfo});
    } else {
      logger.error('_sendAuthResponse()',
        `Token has been issue for account ${tokenInfo.email} instead of ${authManager.loginHint}`);
      authManager.sendError(authError.account_mismatch, {selectedAccount: tokenInfo.email});
    }
  }
}

/**
 * Event handler executed in case the access token validation failed.
 * @param {AuthManager} authManager - AuthManager instance
 * @param {Object} response - failed token validation response
 * @private
 */
function _handleTokenValidationError(authManager, response) {
  logger.error('_handleTokenValidationError()', 'Token validation failed:', response);
  authManager.sendError(authError.token_validation_failed, response);
}

export const validateToken = AuthManager.validateToken;
export const revokeToken = AuthManager.revokeToken;
export const handleAuthError = AuthManager.handleAuthError;
export const serializeAuthResult = AuthManager.serializeAuthResult;
