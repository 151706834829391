/**********************************************************************************
 * Module:          smartbarNoAODocsAuthDialog
 *
 * Description:     Auth dialog displayed if immediate auth using AODocs
 *                  client id failed. Meaning that the AODocs App is not installed
 *                  on the domain (possibly because the user is an external user)
 **********************************************************************************/

import AuthDialog from '../authDialog';
import smartbarAuthDialogUtils from './authDialogUtils';
import {validateToken, handleAuthError} from '../../authManager';

const envConfig = require(`../../config/env/${ENV}`);

const smartbarNoAODocsAuthDialog = new AuthDialog({

  name: 'sbNoAODocs',

  getContent: () => {
    const contentElement = document.createElement('div');
    contentElement.appendChild(_getContextInfoSection());
    return contentElement;
  },

  onAuthButtonClick: () => {
    const {clientId, scopes} = smartbarAuthDialogUtils.getAuthConfig();
    smartbarNoAODocsAuthDialog.authManager.authorize(false, clientId, scopes, validateToken, handleAuthError);
  }

});

/**
 * Get the "context" section element, explaining why this dialog is displayed.
 * @return {HTMLElement} "context" section element
 * @private
 */
function _getContextInfoSection() {
  const contextInfoElement = document.createElement('div');
  contextInfoElement.classList.add('ao-explain');
  contextInfoElement.innerHTML = AuthDialog.i18n(
    'aodocsNotInstalled', {aodocsInstallUrl: envConfig.aodocsInstallUrl});
  return contextInfoElement;
}

export default smartbarNoAODocsAuthDialog;
