/*********************************************
 * Module:          smartbarAuthDialog
 *
 * Description:     Auth dialog for the
 *                  AODocs Smartbar extension
 *********************************************/

import Logger from '../../utils/logger';
import AuthDialog from '../authDialog';
import authConfig from '../../authConfig';
import {authError} from '../../authMessage';
import smartbarAuthDialogUtils from './authDialogUtils';
import smartbarNoAODocsAuthDialog from './noAODocsAuthDialog';
import {validateToken, handleAuthError, serializeAuthResult} from '../../authManager';

const logger = new Logger('AuthFrame', 'SmartbarAuthDialog');

const smartbarAuthDialog = new AuthDialog({

  name: 'smartbar',

  getContent: () => {
    const contentElement = document.createElement('div');
    contentElement.appendChild(_getContextInfoSection());
    contentElement.appendChild(_getScopeCheckboxes());
    return contentElement;
  },

  getNotes: () => [
    AuthDialog.i18n('notSelectedAppNote'),
    AuthDialog.i18n('excludeAccountNote')
  ],

  getFootnotes: () => {
    return smartbarAuthDialogUtils.getGoogleUserDataPolicyDisclosure();
  },

  beforeOpen: () => {
    _getGmailCheckbox().checked = smartbarAuthDialog.authManager.scope.includes(authConfig.scopes.gmail);
  },

  onAuthButtonClick: () => {
    const isGmailChecked = _getGmailCheckbox().checked;
    const {clientId, scopes} = smartbarAuthDialogUtils.getAuthConfig();
    if (_isAuthUpdate()) {
      // If auth update, always do interactive auth to avoid to have to display a third dialog if immediate fails
      smartbarAuthDialog.authManager.authorize(false, clientId, scopes, validateToken, handleAuthError);
    } else if (_hasScopeChanged(isGmailChecked)) {
      // If the scope has changed, immediate auth can succeed this time
      smartbarAuthDialog.authManager.authorize(true, clientId, scopes, validateToken, _onAuthFailed);
    } else {
      // If same scopes as the initial auth attempt, launch interactive auth
      smartbarAuthDialog.authManager.authorizeWithDefaultHandlers(false);
    }
  }

});

/**
 * Get the "context" section element, containing authentication context info.
 * @return {HTMLElement} "context" section element
 * @private
 */
function _getContextInfoSection() {
  const contextInfoElement = document.createElement('div');
  contextInfoElement.classList.add('ao-explain');
  contextInfoElement.innerHTML = AuthDialog.i18n('contextInfo', {user: smartbarAuthDialog.authManager.loginHint});
  return contextInfoElement;
}

/**
 * Get the element containing application scopes checkboxes.
 * @return {HTMLElement} element containing application scopes checkboxes
 * @private
 */
function _getScopeCheckboxes() {
  const scopesContainer = document.createElement('div');
  scopesContainer.classList.add('ao-scopes');
  scopesContainer.appendChild(_getScopeCheckbox('mail', false));
  scopesContainer.appendChild(_getScopeCheckbox('drive', true));
  return scopesContainer;
}

/**
 * Get a checkbox + label elements representing an application scope.
 * @param {String} scope - scope name
 * @param {Boolean} disabled - true if the checkbox must be disabled
 * @return {HTMLElement} a container with checkbox + label elements
 * @private
 */
function _getScopeCheckbox(scope, disabled) {

  const id = `${scope}Scope`;
  const checkboxContainer = document.createElement('div');
  const checkboxInputElement = document.createElement('input');
  const checkboxLabelElement = document.createElement('label');
  const checkboxLabelTextElement = document.createElement('span');

  checkboxInputElement.setAttribute('type', 'checkbox');
  checkboxInputElement.setAttribute('id', id);
  checkboxInputElement.setAttribute('checked', '');
  if (disabled) {
    checkboxInputElement.setAttribute('disabled', '');
  }
  checkboxLabelTextElement.innerHTML = AuthDialog.i18n(
    `${scope}Scope`, {logo: `<img src="images/google-${scope}-logo.png"/>`});
  checkboxLabelElement.setAttribute('for', id);
  checkboxLabelElement.appendChild(checkboxLabelTextElement);
  checkboxContainer.classList.add('ao-md-checkbox');
  checkboxContainer.classList.toggle('disabled', disabled);
  checkboxContainer.appendChild(checkboxInputElement);
  checkboxContainer.appendChild(checkboxLabelElement);

  return checkboxContainer;
}

/**
 * Get the Gmail scheckbox element.
 * @return {Element} Gmail checkbox
 * @private
 */
function _getGmailCheckbox() {
  return smartbarAuthDialog.getElement().querySelector('#mailScope');
}

/**
 * Check if the scopes to authorized are different from the ones requested when the auth frame was loaded.
 * @param {Boolean} isGmailChecked - true if the 'Gmail' checkbox is ticked
 * @return {Boolean} the result of the check
 * @private
 */
function _hasScopeChanged(isGmailChecked) {
  const isGmailInRequestedScopes = smartbarAuthDialog.authManager.scope.includes(authConfig.scopes.gmail);
  return (isGmailChecked && !isGmailInRequestedScopes) || (!isGmailChecked && isGmailInRequestedScopes);
}

/**
 * Check if the current auth type is an update.
 * @return {Boolean} the result of the check
 * @private
 */
function _isAuthUpdate() {
  return smartbarAuthDialog.authManager.authType === authConfig.authType.update;
}

/**
 * Event handler executed if immediate auth has failed.
 * @param {AuthManager} authManager - AuthManager instance
 * @param {GoogleApiOAuth2TokenObject} authResult - auth result data
 * @private
 */
function _onAuthFailed(authManager, authResult) {
  const serializedAuthResult = serializeAuthResult(authManager, authResult);
  if (serializedAuthResult && serializedAuthResult.error.toLowerCase() === authError.immediate_failed) {
    logger.warn('_onAuthFailed()',
      'Immediate auth failed, AODocs application not installed, updating dialog to allow interactive mode', authResult);
    // Hide dialog, instead of closing as the extension is watching close events to remove the iframe
    smartbarAuthDialog.hide();
    // We can't do an immediate=false auth programmatically due to the popup blocker, so we display
    // another dialog to explain the situation, and show an "Authorize" button to start interactive auth
    smartbarNoAODocsAuthDialog.create(authManager).open();
    authManager.sendError(authError.immediate_failed, serializedAuthResult);
  } else {
    handleAuthError(authManager, authResult);
  }
}

export default smartbarAuthDialog;
